/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React, { useEffect, useState } from "react";
import "./styles/main.scss";
import { Container } from "reactstrap";
import Header from "./components/Header/Header";
import OrderHeader from "./components/OrderHeader/OrderHeader";
import OrderItem from "./components/OrderItem/OrderItem";
import Analytics from "./components/analytics/Analytics";
import {parseUrl, processBrandName} from "./utils/utils";
import OrderPagination from "./components/OrderPagination/OrderPagination";
import Footer from "./components/Footer/Footer";
import { URL } from "./utils/connections";
import { Brands } from "./utils/brands";
import { Helmet } from "react-helmet";

function App(props) {
  const { orderItemsPerPage } = props;
  const [itemData, setItemData] = useState({});
  const [page, setPage] = useState(1);
  const [fetchError, setFetchError] = useState(null);
  const { id } = parseUrl();
    const [status, setStatus] = useState(false);
  const [brandTitle, setBrandTitle] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [styledBrand, setStyledBrand] = useState(false);
  const [legalUrl, setLegalUrl] = useState(null);
  const [copyright, setCopyright] = useState( null);

  useEffect(() => {

    const body = document.body;
    if (body.classList.length === 0) {
        body.classList.add("brand--default");
    }

    //Fetch data from backend
      if(!status) {
          fetch(URL + encodeURIComponent(id))
              .then(response => {
                  if (response.ok) {
                      setStatus(true);
                      setFetchError(null);
                      return response.json();
                  } else {
                      setFetchError(response.status);
                      throw Error(`Network response with status ${response.status}`);
                  }
              })
              .then((data) => { setItemData(data); return data })
              .then(data => getBrandFromData(data))
              .catch(err => {
                  console.error(err);
              });
      }
  });

  const renderOrderItems = (orderItems) => {
    return orderItems.map((item, key) => <OrderItem key={key} {...item} formatDate={formatDate} />);
  };

  const getBrandFromData = (data) => {
      let division = data.division != null ? data.division : '';
      let salesOrganization = data.salesOrganization != null ? data.salesOrganization : '';
      let fullBrandName =  division + "-" + salesOrganization;

      let brand = fullBrandName.length !== 1 ? processBrandName(fullBrandName) : '00';
      let brandName = Brands[brand].name;
      let styledBrand = Brands[brand].branded;

      setLegalUrl(Brands[brand].legal ? Brands[brand].legal : Brands["00"].legal);
      setCopyright(Brands[brand].copyright ? Brands[brand].copyright : Brands["00"].copyright);
      setBrandTitle(Brands[brand].title ? Brands[brand].title : '');
      setBrandName(Brands[brand].name ? Brands[brand].name : '');
      setStyledBrand(styledBrand);

      document.body.classList.remove(...document.body.classList);
      document.body.classList.add(`brand--${brand && styledBrand ? brandName : 'default'}`);
  };

    const formatDate = (shortDate) => {
        const months = {
            "01": 'January',
            "02": 'February',
            "03": 'March',
            "04": 'April',
            "05": 'May',
            "06": 'June',
            "07": 'July',
            "08": 'August',
            "09": 'September',
            "10": 'October',
            "11": 'November',
            "12": 'December'
        };

        if (shortDate) {
            let parts = shortDate.split('/');
            if (parts) {
                let day = parts[0];
                let month = parts[1];
                let year = parts[2];

                let newMonth = months[month];

                return day + " " + newMonth + " " + year;
            }

            return shortDate;
        }

        return shortDate;
    };

  return (
    <div className="App">
      {/* Use react-helmet to set the page title, favicon and font */}
        <Helmet>
            <title>Order tracking{brandTitle ? ` - ${brandTitle}` : ``}</title>
            {styledBrand && brandName &&
            <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/brands/${brandName}/favicon.ico`} />
            }
            <link href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap" rel="stylesheet" />
        </Helmet>
      <Header {...itemData} />
      <main className="act-main">
        <Container>
          {itemData && itemData.orderItems && itemData.orderItems.length > 0 &&
            <React.Fragment>
              <OrderHeader />
              {renderOrderItems(itemData.orderItems)}
            </React.Fragment>
          }
          {fetchError &&
            <div className="act-error">
              <h2>Order not found - {fetchError}</h2>
              <p>Sorry, the order you are looking for cannot be found.</p>
            </div>
          }
          <Footer legalNoticeUrl={legalUrl} copyright={copyright} />
        </Container>
      </main>
        <Analytics {...itemData} />
    </div>
  );
}

export default App;
