/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React from "react";
import { Row, Col } from "reactstrap";
import Media from "react-media";
import GlobalConstants from "../../constants/global";

export default function OrderHeader() {
  const { MEDIA_QUERIES } = GlobalConstants;

  return (
    <div className="act-order-header">
      <Row noGutters={true}>
        <Col xs="3">
          <div className="act-order-header__item act-order-header__item--first">Material details</div>
        </Col>
        <Col xs="1">
          <Media query={MEDIA_QUERIES.MEDIUM_ONLY}>
            {matches =>
              matches ? (
                <div className="act-order-header__item">Qty</div>
              ) : (
                <div className="act-order-header__item">Quantity</div>
              )
            }
          </Media>
        </Col>
        <Col xs="2">
          <div className="act-order-header__item">Status</div>
        </Col>
        <Col xs="2">
          <div className="act-order-header__item">(Estimated) shipping date</div>
        </Col>
        <Col xs="2">
          <div className="act-order-header__item">(Estimated) delivery date</div>
        </Col>
        <Col xs="2">
          <div className="act-order-header__item">Tracking info</div>
        </Col>
      </Row>
    </div>
  );
}
