/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React, { useState } from "react";

export default function Analytics(props) {
    const { digitalData } = props;
    const { orderInfo: orderInfo } = digitalData || {};
    const { division: division, salesOrganization : salesOrganization, partnerNumber : partnerNumber } = orderInfo || {};
  return (
    division && salesOrganization && partnerNumber ?
      <div id="analytics-container">
        <input type="hidden" id="analytics-data-division" value={division}/>
        <input type="hidden" id="analytics-data-sales-organziation" value={salesOrganization}/>
        <input type="hidden" id="analytics-data-partner-number" value={partnerNumber}/>
      </div>
    : null 
  );

};
