/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */
export const Brands = {
  "00": { title: "", name: "Unbranded", legal: "https://www.atlascopco.com/privacyportal/en", copyright: "© 2017-2019 Atlas Copco AB"},
  "01": { title: "Atlas Copco", name: "atlas-copco", branded: true, legal: "https://www.atlascopco.com/privacyportal/en", copyright: "© 2017-2019 Atlas Copco AB"},
  "02": { title: "Abird", name: "abird"},
  "03": { title: "Ceccato", name: "ceccato", branded: true, legal: "https://www.ceccato.com/en/Aboutus/legal-notice", copyright: "© 2019 Ceccato"},
  "04": { title: "CP Chicago Pneumatic", name: "cp-chicago-pneumatic", branded: true, legal: "https://www.cp.com/en/legal", copyright: "© 2019 Chicago Pneumatic"},
  "05": { title: "ALUP GrassAir", name: "alup-grassair"},
  "06": { title: "Irmer Elze", name: "irmer-elze"},
  "07": { title: "Liutech", name: "liutech"},
  "08": { title: "Mark", name: "mark", branded: true, legal: "https://www.mark-compressors.com/en/legal-notice", copyright: "© 2019 Mark"},
  "10": { title: "Mauguiere", name: "mauguiere", branded: true},
  "11": { title: "Rand-Air", name: "rand-air"},
  "12": { title: "Worthington Creyssen", name: "worthington-creyssen", branded: true, legal: "https://www.worthington-creyssensac.com/en/legal-notice", copyright: "© 2019 Worthington Creyssensac"},
  "15": { title: "Desoutter", name: "desoutter", branded: true, legal: "https://www.desouttertools.com/legal/legal-notice-and-privacy-policy", copyright: "© 2020 Desoutter Industrial Tools"},
  "16": { title: "CP Georges Renault", name: "cp-georges-renault"},
  "20": { title: "Prime Energy", name: "prime-energy"},
  "22": { title: "Shenyang", name: "shenyang"},
  "23": { title: "Puska", name: "puska", branded: true, legal: "https://www.puska.com/es", copyright: "© Puska"},
  "27": { title: "Rent Service", name: "rent-service"},
  "28": { title: "Kolfor", name: "kolfor"},
  "29": { title: "Prime Industrial Ren", name: "prime-industrial-ren"},
  "30": { title: "Intermech", name: "intermech"},
  "31": { title: "Pneumatech", name: "pneumatech", branded: true, legal: "https://www.pneumatech.com/en/about-pneumatech/legal-notice", copyright: "© 2019 Pneumatech"},
  "32": { title: "Fuji Air Tools", name: "fuji-air-tools"},
  "33": { title: "BeaconMedaes", name: "beaconmedaes", branded: true},
  "34": { title: "Lutos", name: "lutos"},
  "35": { title: "Abac", name: "abac", branded: true, legal: "https://www.abacaircompressors.com/en/legal-notice", copyright: "© 2011-2018 ABAC air compressors"},
  "36": { title: "Alup", name: "alup", branded: true, legal: "https://www.alup.com/en/About-us0/Legal_notice", copyright: "© 2019 Alup Kompressoren"},
  "37": { title: "Belaire", name: "belaire"},
  "38": { title: "Balma", name: "balma", branded: true, legal: "https://www.balma.com/en/sys/privacy-policy", copyright: "© 2019 Balma"},
  "39": { title: "Agre", name: "agre", branded: true, legal: "https://www.agre.de/de/datenschutzerklaerung", copyright: "© 2019 Agre Kompressoren"},
  "40": { title: "Microtec", name: "microtec"},
  "41": { title: "Dynapac", name: "dynapac"},
  "42": { title: "GreenField", name: "greenfield"},
  "43": { title: "Mafi-Trench", name: "mafi-trench"},
  "44": { title: "Tremix", name: "tremix"},
  "46": { title: "Bolaite", name: "bolaite"},
  "47": { title: "Creemers", name: "creemers", branded: true, legal: "https://www.creemers.nl/nl/Aboutus/juridische-informatie", copyright: "© 2019 Creemers"},
  "48": { title: "OMA", name: "oma"},
  "50": { title: "Rodcraft", name: "rodcraft", branded: true, legal: "http://www.rodcraft.com/legal/", copyright: "©Rodcraft 2020"},
  "51": { title: "Fluidcon", name: "fluidcon"},
  "52": { title: "", name: "private-brand"},
  "53": { title: "Quincy", name: "quincy", legal: "https://www.quincycompressor.com/about-us/legal-notice/", copyright: "© 2022 Quincy Compressor"},
  "54": { title: "Cirmac", name: "cirmac"},
  "55": { title: "EDMAC", name: "edmac"},
  "56": { title: "Sogimair", name: "sogimair", branded: true, legal: "https://www.sogimair.com/es/sys/legal-notice", copyright: "© 2019 Sogimair"},
  "57": { title: "SCA", name: "sca"},
  "58": { title: "Linghein", name: "linghein"},
  "60": { title: "Seti-Tec", name: "seti-tec"},
  "61": { title: "Saltus", name: "saltus"},
  "62": { title: "Gesan", name: "gesan"},
  "63": { title: "Ekomak", name: "ekomak", branded: true, legal: "http://www.ekomak.com/tr/index.html", copyright: "© 2019 EKOMAK KOMPRESÖRLERİ Tüm Hakları Saklıdır."},
  "64": { title: "ShengdaAir", name: "shengdaair"},
  "65": { title: "Penlon Medical Gas", name: "penlon-medical-gas"},
  "66": { title: "American Pneumatic T", name: "american-pneumatic-t", branded: true, legal: "https://www.apt-tools.com/en/sys/legalnotice", copyright: "© 2019 APT (a business unit within Power Technique North America LLC)"},
  "67": { title: "Rapid Torc", name: "rapid-torc"},
  "68": { title: "Spiral Air", name: "spiral-air"},
  "69": { title: "Gazcon", name: "gazcon"},
  "70": { title: "Rapid-Torc", name: "rapid-torc"},
  "71": { title: "Perfora", name: "perfora"},
  "72": { title: "HSI", name: "hsi"},
  "73": { title: "WIS Air", name: "wis-air"},
  "74": { title: "Henrob", name: "henrob"},
  "75": { title: "Titan", name: "titan"},
  "76": { title: "FIAC", name: "fiac", branded: true},
  "77": { title: "CleanAir", name: "clean-air"},
  "78": { title: "Leybold", name: "leybold"},
  "79": { title: "CSK", name: "csk"},
  "80": { title: "Varisco", name: "varisco"},
  "81": { title: "Lifton", name: "lifton"},
  "82": { title: "Schneider airystems", name: "schneider-airystems"},
  "83": { title: "Pneumatech medical s", name: "pneumatech-medical-s", legal: "https://www.p-mgs.com/en/about-us/legal-notice", copyright: "© 2019 Pneumatech MGS"},
  "84": { title: "Erkat", name: "erkat"},
  "85": { title: "GIA", name: "gia"},
  "99": { title: "Common Parts", name: "common-parts"},
  "A3": { title: "Michelin", name: "michelin"},
  "A4": { title: "AEG", name: "aeg"},
  "OB": { title: "", name: "other-brand"},
  "ZZ": { title: "", name: "competitor-brand"},
  "EP": { title: "Epiroc", name: "epiroc", branded: true, legal: "https://www.epiroc.com/en-uk/legal-notice", copyright: "© 2019 Epiroc"},
  "ST": { title: "Servatechnik", name: "servatechnik", branded: true, legal: " ", copyright: " "},
  "D2": { title: "Piston Technology Center", name: "piston-technology-center", legal: "https://www.pistontechnologycenter.com/privacy-policy/legal-notice/", copyright: "© 2022 Piston Technology Center"}
};
