/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Collapse, Button, Row, Col } from "reactstrap";
import OrderItemHistory from "./OrderItemHistory/OrderItemHistory";
import Media from "react-media";
import Arrow from "../Icons/Arrow";
import GlobalConstants from "../../constants/global";
import StatusConstants from "../../constants/status";

export default function OrderItem(props) {
  const {
    description,
    partNumber,
    quantity,
    orderStatus,
    shippingDate,
    estimatedShippingDate,
    deliveryDate,
    estimatedDeliveryDate,
    trackingInfo,
    partiallyProcessed,
    accountingDocNumbers,
    signerName
  } = props;
  const [collapsed, setCollapsed] = useState(true);
  const { trackingNumber, trackingWebsite } = trackingInfo || {};
  const { MEDIA_QUERIES } = GlobalConstants;
  const { STATUS_DISPLAY_NAMES } = StatusConstants;
  const orderStatusDisplayName = STATUS_DISPLAY_NAMES[orderStatus];

  const renderAcountingDocNumbers = (accountingDocNumbers) => (
      accountingDocNumbers.map(adn => adn != null ? <div key={adn}>{`Invoice number ${adn}`}</div> : '')
  );

  const renderOrderItemHistory = (partiallyProcessed) =>  {
    if (partiallyProcessed) {
      const historyItems = partiallyProcessed.processHistoryItems;

      return historyItems.map((item, key) =>
        key % 2 === 0 ? (
          <OrderItemHistory number={key} key={key} {...item} light formatDate={formatDate} />
        ) : (
          <OrderItemHistory number={key} key={key} {...item} formatDate={formatDate}/>
        )
      );
    }
    return null;
  };

  const formatDate = (shortDate) => {
    return props.formatDate(shortDate);
  };

  const getTrackingNumberFromTrackingWebsite = (trackingWebsite) => {
    let indexOfLastEqualSign = trackingWebsite.lastIndexOf("=");
    return trackingWebsite.substring(indexOfLastEqualSign+1, trackingWebsite.length);
  };

  return (
    <div className="act-order-item">
      <Media query={MEDIA_QUERIES.MEDIUM_UP}>
        {matches =>
          matches ? (
            <Row noGutters={true}>
              <Col xs="3" className="d-flex flex-column">
                <div className="act-order-item__col act-order-item__col--first">
                  <div className="act-order-item__description">{description}</div>
                  <div className="act-order-item__description-detail">Part number {partNumber}</div>
                  <div className="act-order-item__description-detail">{accountingDocNumbers && renderAcountingDocNumbers(accountingDocNumbers)}</div>
                </div>
              </Col>
              <Col xs="1">
                <div className="act-order-item__col">
                  {quantity}
                </div>
              </Col>
              <Col xs="2">
                <div className="act-order-item__col">
                  {orderStatusDisplayName}
                </div>
              </Col>
              <Col xs="2">
                <div className="act-order-item__col">
                  {shippingDate ? formatDate(shippingDate) : formatDate(estimatedShippingDate)}
                </div>
              </Col>
              <Col xs="2">
                <div className="act-order-item__col font-weight-bold">
                  {deliveryDate ? formatDate(deliveryDate) : formatDate(estimatedDeliveryDate)}
                </div>
              </Col>
              <Col xs="2">
                <div className="act-order-item__col">
                  {trackingNumber && trackingWebsite &&
                    <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{trackingNumber}</a>
                  }
                  {!trackingNumber && trackingWebsite &&
                    <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{getTrackingNumberFromTrackingWebsite(trackingWebsite)}</a>
                  }
                  {signerName &&
                    <div>Signed by {signerName}</div>
                  }
                </div>
              </Col>
            </Row>
          ) : (
            <Row noGutters={true}>
              <Col xs="12" className="d-flex flex-column">
                <div className="act-order-item__col act-order-item__col--first">
                  <div className="act-order-item__col-label">Material details</div>
                  <div className="act-order-item__description">{description}</div>
                  <div className="act-order-item__description-detail">Part number {partNumber}</div>
                  <div className="act-order-item__description-detail">{accountingDocNumbers && renderAcountingDocNumbers(accountingDocNumbers)}</div>
                </div>
              </Col>
              <Col xs="6" className="d-flex flex-column">
                <div className="act-order-item__col">
                  <div className="act-order-item__col-label">Quantity</div>
                  <div className="font-weight-bold">{quantity}</div>
                </div>
              </Col>
              <Col xs="6">
                {orderStatus &&
                  <div className="act-order-item__col">
                    <div className="act-order-item__col-label">Status</div>
                    <div>{orderStatusDisplayName}</div>
                  </div>
                }
              </Col>
              <Col xs="6">
                {(shippingDate || estimatedShippingDate) &&
                  <div className="act-order-item__col">
                    <div className="act-order-item__col-label">(Estimated) shipping date</div>
                    <div>{shippingDate ? formatDate(shippingDate) : formatDate(estimatedShippingDate)}</div>
                  </div>
                }
              </Col>
              <Col xs="6">
                {(deliveryDate || estimatedDeliveryDate) &&
                  <div className="act-order-item__col">
                    <div className="act-order-item__col-label">(Estimated) delivery date</div>
                    <div className="font-weight-bold">{deliveryDate ? formatDate(deliveryDate) : formatDate(estimatedDeliveryDate)}</div>
                  </div>
                }
              </Col>
              <Col xs="12">
                {trackingNumber && trackingWebsite &&
                  <div className="act-order-item__col">
                    <div className="act-order-item__col-label">Tracking info</div>
                    <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{trackingNumber}</a>
                  </div>
                }
                {!trackingNumber && trackingWebsite &&
                  <div className="act-order-item__col">
                    <div className="act-order-item__col-label">Tracking info</div>
                    <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{getTrackingNumberFromTrackingWebsite(trackingWebsite)}</a>
                  </div>
                }
                {signerName &&
                  <div>Signed by {signerName}</div>
                }
              </Col>
            </Row>
          )
        }
      </Media>
      {partiallyProcessed ? (
        <Row noGutters={true}>
          <Col xs="12">
            <div className="act-order-item__collapse-row">
              <Button onClick={() => setCollapsed(!collapsed)} color="link" className="act-order-item__collapse-btn">
                <span>{collapsed ? "Show details" : "Hide details"}</span>
                <Arrow className={`act-icon-arrow act-icon-arrow--${collapsed ? 'bottom' : 'top'}`} />
              </Button>
            </div>
          </Col>
        </Row>
      ) : null}
      <Collapse isOpen={!collapsed} className="act-order-item__history">
        {renderOrderItemHistory(partiallyProcessed)}
      </Collapse>
    </div>
  );
}

OrderItem.defaultProps = {
  trackingInfo: {},
};

OrderItem.propTypes = {
  trackingInfo: PropTypes.shape({
    trackingNumber: PropTypes.string,
    trackingWebsite: PropTypes.string,
  }),
};
