/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React, { useState } from "react";
import { Collapse, Row, Col } from "reactstrap";
import Media from "react-media";
import Arrow from "../../Icons/Arrow";
import GlobalConstants from "../../../constants/global";
import StatusConstants from "../../../constants/status";

export default function OrderItemHistory(props) {
  const [collapsed, setCollapsed] = useState(true);
  const { quantity, orderStatus, shippingDate, estimatedShippingDate, deliveryDate, estimatedDeliveryDate, trackingInfo, number, light, signerName } = props;
  const { trackingNumber, trackingWebsite } = trackingInfo || {};
  const { MEDIA_QUERIES } = GlobalConstants;
  const { STATUS_DISPLAY_NAMES } = StatusConstants;
  const orderStatusDisplayName = STATUS_DISPLAY_NAMES[orderStatus];

  const rowBgColor = light ? "light" : "dark";

  const formatDate = (shortDate) => {
    return props.formatDate(shortDate);
  };

  const getTrackingNumberFromTrackingWebsite = (trackingWebsite) => {
    let indexOfLastEqualSign = trackingWebsite.lastIndexOf("=");
    return trackingWebsite.substring(indexOfLastEqualSign+1, trackingWebsite.length);
  };

  return (
    <Media query={MEDIA_QUERIES.MEDIUM_UP}>
      {matches =>
        matches ? (
          <Row noGutters={true} className={`act-order-item__history-row act-order-item__history-row--${rowBgColor}`}>
            <Col xs="3">
              <div className="act-order-item__history-col act-order-item__history-col--first">{number + 1}</div>
            </Col>
            <Col xs="1">
              <div className="act-order-item__history-col font-weight-bold">{quantity}</div>
            </Col>
            <Col xs="2">
              <div className="act-order-item__history-col">{orderStatusDisplayName}</div>
            </Col>
            <Col xs="2">
              <div className="act-order-item__history-col">{shippingDate ? formatDate(shippingDate) : formatDate(estimatedShippingDate)}</div>
            </Col>
            <Col xs="2">
              <div className="act-order-item__history-col font-weight-bold">{deliveryDate ? formatDate(deliveryDate) : formatDate(estimatedDeliveryDate)}</div>
            </Col>
            <Col xs="2">
              <div className="act-order-item__history-col">
                {trackingNumber && trackingWebsite &&
                  <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{trackingNumber}</a>
                }
                {!trackingNumber && trackingWebsite &&
                <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{getTrackingNumberFromTrackingWebsite(trackingWebsite)}</a>
                }
                {signerName &&
                <div>Signed by {signerName}</div>
                }
              </div>
            </Col>
          </Row>
        ) : (
          <React.Fragment>
            <Row noGutters={true} className={`act-order-item__history-row act-order-item__history-row--${rowBgColor}`} onClick={() => setCollapsed(!collapsed)}>
              <Col xs="6">
                <div className="act-order-item__history-col act-order-item__history-col--first">{number + 1}</div>
              </Col>
              <Col xs="6">
                <div className="act-order-item__history-col d-flex justify-content-between">
                  <div>{orderStatusDisplayName}</div>
                  <div className="act-order-item__history-collapse-btn">
                    <Arrow className={`act-icon-arrow act-icon-arrow--${collapsed ? 'bottom' : 'top'}`} />
                  </div>
                </div>
              </Col>
            </Row>
            <Collapse isOpen={!collapsed} className="act-order-item__history-collapsed-row">
              <Row noGutters={true}>
                {quantity &&
                  <Col xs="12">
                    <div className="act-order-item__history-collapsed-col">
                      <div className="act-order-item__col-label">Quantity</div>
                      <div className="font-weight-bold">{quantity}</div>
                    </div>
                  </Col>
                }
                {(shippingDate || estimatedShippingDate) &&
                  <Col xs="6">
                    <div className="act-order-item__history-collapsed-col">
                      <div className="act-order-item__col-label">(Estimated) shipping date</div>
                      <div>{shippingDate ? formatDate(shippingDate) : formatDate(estimatedShippingDate)}</div>
                    </div>
                  </Col>
                }
                {(deliveryDate || estimatedDeliveryDate) &&
                  <Col xs="6">
                    <div className="act-order-item__history-collapsed-col">
                      <div className="act-order-item__col-label">(Estimated) delivery date</div>
                      <div className="font-weight-bold">{deliveryDate ? formatDate(deliveryDate) : formatDate(estimatedDeliveryDate)}</div>
                    </div>
                  </Col>
                }
                {trackingNumber && trackingWebsite &&
                  <Col xs="12">
                    <div className="act-order-item__history-collapsed-col">
                      <div className="act-order-item__col-label">Tracking info</div>
                      <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{trackingNumber}</a>
                      {signerName &&
                      <div>Signed by {signerName}</div>
                      }
                    </div>
                  </Col>
                }
                {!trackingNumber && trackingWebsite &&
                  <Col xs="12">
                    <div className="act-order-item__history-collapsed-col">
                      <div className="act-order-item__col-label">Tracking info</div>
                      <a href={trackingWebsite} target="_blank" rel="noopener noreferrer">{getTrackingNumberFromTrackingWebsite(trackingWebsite)}</a>
                      {signerName &&
                      <div>Signed by {signerName}</div>
                      }
                    </div>
                  </Col>
                }
              </Row>
            </Collapse>
          </React.Fragment>
        )
      }
    </Media>
  );
}
