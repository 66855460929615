/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React from "react";
import PropTypes from "prop-types";

export default function Footer(props) {
  const { legalNoticeUrl, copyright } = props;

  if (legalNoticeUrl && copyright) {
    return (
      <footer className="act-footer">
        <span className="act-footer__copyright">{copyright}</span>
        <span className="act-footer__break">|</span>
        <a href={legalNoticeUrl} className="act-footer__legal-notice">Legal notice & cookies</a>
      </footer>
    );
  }
  return null;
}

Footer.defaultProps = {
  legalNoticeUrl: null,
  copyright: null,
};

Footer.propTypes = {
  legalNoticeUrl: PropTypes.string,
  copyright: PropTypes.string,
};

