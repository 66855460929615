/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

const BREAKPOINTS = {
  SMALL: '576',
  MEDIUM: '768',
  LARGE: '992',
  XLARGE: '1200',
};

const BREAKPOINT_MAX_DISTRACT = .02;

const MEDIA_QUERIES = {
  SMALL_ONLY: `(max-width: ${BREAKPOINTS.SMALL - BREAKPOINT_MAX_DISTRACT}px)`,
  MEDIUM_ONLY: `(min-width: ${BREAKPOINTS.MEDIUM}px) and (max-width: ${BREAKPOINTS.LARGE - BREAKPOINT_MAX_DISTRACT}px)`,
  MEDIUM_UP: `(min-width: ${BREAKPOINTS.MEDIUM}px)`,
  LARGE_ONLY: `(min-width: ${BREAKPOINTS.LARGE}px) and (max-width: ${BREAKPOINTS.XLARGE - BREAKPOINT_MAX_DISTRACT}px)`,
  LARGE_UP: `(min-width: ${BREAKPOINTS.LARGE}px)`,
  XLARGE_UP: `(min-width: ${BREAKPOINTS.XLARGE}px)`,
};

const GlobalConstants = {
  BREAKPOINTS,
  MEDIA_QUERIES,
};

export default GlobalConstants;
