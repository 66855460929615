/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React from 'react';

const Arrow = props => (
  <svg
    viewBox="0 0 8 12"
    focusable="false"
    {...props}
  >
    <path
      d="M7 1L2 6l5 5"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default Arrow;
