/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

export function parseUrl() {
  const parsedUrl = new URL(window.location.href);
  const id = parsedUrl.searchParams.get("id");
  const lang = parsedUrl.searchParams.get("lang");
  return { id, lang };
}


export function processBrandName(brand) {
    let splittedBrand = brand.split("-");
    if(splittedBrand.length > 1) {
        if (splittedBrand[0] === "01" || splittedBrand[0] === "04") {
            if (splittedBrand[1] === "GB70") {
                return "EP";
            }
        }
        if (splittedBrand[0] === "12") {
            if (splittedBrand[1] === "CH20") {
                return "ST";
            }
        }
        return splittedBrand[0];
    }
    return brand;
}
