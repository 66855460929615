/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

import React from "react";
import PropTypes from 'prop-types';
import { Container, Row, Col } from "reactstrap";

export default function Header(props) {
  const { brandOrderNumber, customerPurchaseOrder, contact, salesOrderNumber, division } = props;
  const { name: contactName, email: contactEmail, telephone: contactTelephone } = contact || {};

  return (
    <header className="act-header">
      <Container>
        <Row className="no-gutters">
          <Col md="4" lg="6">
            <div className="act-header__brand">
              <div className="act-header__logo"></div>
              <h1>Order tracking</h1>
            </div>
          </Col>
          <Col md="4" lg="3">
            {(salesOrderNumber || customerPurchaseOrder) &&
              <div className="act-header__order">
                {customerPurchaseOrder &&
                  <div>
                    <div className="act-header__order-nr-label">Your reference number</div>
                    <div className="act-header__order-nr">{customerPurchaseOrder}</div>
                  </div>
                }
                {salesOrderNumber &&
                  <div>
                    <span className="act-header__purchase-nr-label">Order number</span>
                    <span className="act-header__purchase-nr">{salesOrderNumber}</span>
                  </div>
                }
              </div>
            }
          </Col>

          {/*no contact info for Schneider(brands.js key 82)*/}
            {(division !== "82") &&
            <Col md="4" lg="3">
            {(contactName || contactEmail || contactTelephone) &&
              <div className="act-header__contact">
                <div className="act-header__contact-label">Contact person</div>
                {contactName &&
                  <div className="act-header__contact-name">{contactName}</div>
                }
                {contactEmail &&
                  <div className="act-header__contact-email">
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                  </div>
                }
                {contactTelephone &&
                  <div className="act-header__contact-telephone">
                    <a href={`tel:${contactTelephone}`}>{contactTelephone}</a>
                  </div>
                }
              </div>
            }
          </Col>
          }
        </Row>
      </Container>
    </header>
  );
}

Header.defaultProps = {
  brandOrderNumber: '',
  customerPurchaseOrder: '',
  contact: {},
  salesOrderNumber : ''
};

Header.propTypes = {
  brandOrderNumber: PropTypes.string,
  salesOrderNumber: PropTypes.string,
  customerPurchaseOrder: PropTypes.string,
  contact: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    telephone: PropTypes.string,
  }),
};
